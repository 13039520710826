import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface ComponentProps {
  title?: string;
  description?: string;
  siteUrl?: string;
  logoUrl?: string;
  language?: string;
  locale?: string;
  twitterHandle?: string;
}

const MetaData: React.FunctionComponent<ComponentProps> = ({
  title,
  description,
  siteUrl,
  logoUrl,
  language,
  locale,
  twitterHandle
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            logoUrl
            language
            locale
            twitterHandle
          }
        }
      }
    `,
  );

  const titleString = title || site.siteMetadata.title;
  const descriptionString = description || site.siteMetadata.description;
  const siteUrlString = siteUrl || site.siteMetadata.siteUrl;
  const logoImageUrl = logoUrl || site.siteMetadata.logoUrl;
  const languageString = language || site.siteMetadata.language;
  const localeString = locale || site.siteMetadata.locale;
  const twitterHandleString = twitterHandle || site.siteMetadata.twitterHandle;

  return (
    <Helmet
      title={`${titleString} | Forslag til firmanavn`}
      htmlAttributes={{ lang: languageString }}
      meta={[
        {
          name: `description`,
          content: descriptionString,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:site`,
          content: twitterHandleString,
        },
        {
          name: `twitter:title`,
          content: titleString,
        },
        {
          name: `twitter:description`,
          content: descriptionString,
        },
        {
          name: `twitter:image`,
          content: logoImageUrl,
        },
        {
          property: `og:title`,
          content: titleString,
        },
        {
          property: `og:description`,
          content: descriptionString,
        },
        {
          property: `og:url`,
          content: siteUrlString,
        },
        {
          property: `og:image`,
          content: logoImageUrl,
        },
        {
          property: `og:site_name`,
          content: titleString,
        },
        {
          property: `og:locale`,
          content: localeString,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ]}
    />
  );
};

export default MetaData;
