import * as React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Routes from '../../constants/Routes';
import Colors from '../../constants/Colors';
import Variables from '../../constants/Variables';

const FooterLinks: React.FunctionComponent = () => {
    
    const { site } = useStaticQuery(
        graphql`
        query {
            site {
            siteMetadata {
                title
            }
            }
        }
        `,
    );
      
    const { title } = site.siteMetadata;

    return (
        <div>
            <div className="inline-block text-center px-1 py-1">
                <span className="copyright" style={copyright}>©{new Date().getFullYear()} {title}</span> 
            </div>
        </div>
    );
}

const firstLegalLink = {
    color: Colors.almostBlack,
    fontFamily: Variables.fontFamilyText,
    fontSize: 14,
    lineHeight: 2
};

const legalLink = {
    color: Colors.almostBlack,
    fontFamily: Variables.fontFamilyText,
    fontSize: 14,
    lineHeight: 2,
    paddingLeft: 20,
    paddingRight: 20
};

const copyright = {
    color: Colors.almostBlack,
    fontFamily: Variables.fontFamilyText,
    fontSize: 14,
    lineHeight: 2,
    opacity: 1
};

export default FooterLinks;