import React from 'react';
import '../../styles/typography.css'
import NavBar from '../navbar/navbar';
import Footer from '../footer/Footer';

interface ComponentProps {
  children: any;
  className?: string;
}

const Layout: React.FunctionComponent<ComponentProps> = ({
  children,
  className,
}) => 
  <main className={`bg-white min-h-screen ${className}`}>
    <NavBar />
    {children}
    <Footer />
  </main>;

Layout.defaultProps = {
  className: ``,
} as Partial<ComponentProps>;

export default Layout;
