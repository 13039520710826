import React, { useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import VegetarLogo from "../images/vegetar-logo.png";
import Routes from '../../constants/Routes';

const NavBar: React.FunctionComponent = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
  );

  const { title } = site.siteMetadata;
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <div className="flex flex-wrap">
      <div className="w-full">
        <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg light-theme-background theme-border-bottom z-50">
          <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
            <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                <Link
                  to="/"
                 >
                <h3 className="mt-1 mb-1">{title}</h3>
              </Link>
              <button
                className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => toggleExpansion(!isExpanded)}
              >
                <span className="block relative w-6 h-px rounded-sm bg-gray-800" />
                <span className="block relative w-6 h-px rounded-sm bg-gray-800 mt-1" />
                <span className="block relative w-6 h-px rounded-sm bg-gray-800 mt-1" />
              </button>
            </div>
            <div
              className={`${ isExpanded ? `block` : `hidden` } w-full block flex-grow lg:flex lg:items-center lg:w-auto`}
              id="example-navbar-info"
            >
              <ul className="flex flex-col lg:flex-row list-none ml-auto">
                <li className="nav-item">
                  <Link
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black-800 hover:opacity-75"
                    to={Routes.FORSLAG}
                  >
                    Andre forslag til firmanavn
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
