import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import SocialComponent from './SocialComponent';
import SvgWaves from './SvgWaves';
import Container from '../layout/Container';
import FooterLinks from './FooterLinks';

const Footer: React.FunctionComponent<{}> = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
  );

  const { title } = site.siteMetadata;

  return (
      <div>
        <SvgWaves />
        <div className="footer light-theme-background text-center pb-10">
          <Container>
            <h3 className="headline mt-0">{title}</h3>
            <p className="text-sm mt-0">Find et firmanavn til din nye virksomhed</p>
            <hr className="mt-6 mb-6" />
            <SocialComponent />
            <FooterLinks />
          </Container>
        </div>
      </div>
  );
};



export default Footer;