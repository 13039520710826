import React from 'react'
import Colors from '../../constants/Colors';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import { graphql, useStaticQuery } from 'gatsby';

const SocialComponent: React.FunctionComponent = () => {

    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        facebookPage
                        instagramPage
                        twitterPage
                    }
                }
            }
        `,
    );
    
    const { facebookPage, instagramPage, twitterPage } = site.siteMetadata;

    return (
        <div>
            <div className="inline-block text-center px-1 py-1">
                <a href={facebookPage} target="_blank" rel="nofollow">
                    <FaFacebook
                        style={icon}
                        size={20}
                    />
                </a>
            </div>
            <div className="inline-block text-center px-1 py-1">
                <a href={instagramPage} target="_blank" rel="nofollow">
                    <FaInstagram
                        style={icon}
                        size={20}
                    />
                </a>
            </div>
            <div className="inline-block text-center px-1 py-1">
                <a href={twitterPage} target="_blank" rel="nofollow">
                    <FaTwitter
                        style={icon}
                        size={20}
                    />
                </a>
            </div>
        </div>
    );
}

const icon = {
    color: Colors.almostBlack,
    opacity: 0.8,
    marginRight: 15,
    cursor: 'pointer'
} as React.CSSProperties;

export default SocialComponent;