const green = '#006665';
const lightgreen = '#EFF6F6';
const red = '#D94267';
const yellow = '#EEBC4E';
const pink = '#F0B8B7';
const veryLightGrey = '#F9F9F9';

export default {
  themeColor: '#EBEBFF',
  lightThemeColor: '#F8F7FF',
  darkThemeColor: '#FFA87D',
  contrastColor: pink,

  borderColor: '#6B57FF',
  textColor: '#99abce',

  // Colors
  white: '#fff',
  black: '#000',
  almostBlack: '#34454C',
  grey: '#737a96',
  lightGrey: '#DBDBD9',
  veryLightGrey,
  green,
  lightgreen,
  red,
  yellow
};